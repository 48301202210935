var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        [
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "fix-form-row margin-top",
                  attrs: {
                    id: "fieldset-1",
                    label: _vm.$t("ml_109706"),
                    "label-cols": "4",
                    "label-align": "right",
                    "label-for": "input-1",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _vm.isEditing
                                ? _c("b-form-datepicker", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      id: "example-datepicker",
                                      right: "",
                                      "date-format-options": {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        weekday: "short",
                                      },
                                    },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  })
                                : _vm._e(),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isPreviewing,
                                    expression: "isPreviewing",
                                  },
                                ],
                                attrs: {
                                  id: "input-1",
                                  disabled: _vm.isPreviewing,
                                  trim: "",
                                },
                                model: {
                                  value: _vm.date,
                                  callback: function ($$v) {
                                    _vm.date = $$v
                                  },
                                  expression: "date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "fix-form-row",
                  attrs: {
                    id: "fieldset-1",
                    label: _vm.$t("ml_109707"),
                    "label-cols": "4",
                    "label-align": "right",
                    "label-for": "input-1",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            [
                              _vm.isEditing
                                ? _c("b-form-timepicker", {
                                    attrs: {
                                      locale: "de",
                                      "hide-header": "",
                                      "no-close-button": "",
                                      right: "",
                                    },
                                    model: {
                                      value: _vm.time,
                                      callback: function ($$v) {
                                        _vm.time = $$v
                                      },
                                      expression: "time",
                                    },
                                  })
                                : _vm._e(),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isPreviewing,
                                    expression: "isPreviewing",
                                  },
                                ],
                                attrs: {
                                  id: "input-1",
                                  disabled: _vm.isPreviewing,
                                  trim: "",
                                },
                                model: {
                                  value: _vm.time,
                                  callback: function ($$v) {
                                    _vm.time = $$v
                                  },
                                  expression: "time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "fix-form-row",
                  attrs: {
                    id: "fieldset-3",
                    label: _vm.$t("ml_109708"),
                    "label-align": "right",
                    "label-cols": "4",
                    "label-for": "input-3",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-3",
                                  disabled: _vm.isPreviewing,
                                  required: "",
                                  state: _vm.vehiclePNumState,
                                  trim: "",
                                },
                                on: { input: _vm.fillCard },
                                model: {
                                  value: _vm.fuelRecord.VehiclePNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fuelRecord, "VehiclePNum", $$v)
                                  },
                                  expression: "fuelRecord.VehiclePNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isEditing && !_vm.qrCodeFlag,
                                      expression: "isEditing && !qrCodeFlag",
                                    },
                                  ],
                                  attrs: { variant: "primary", block: "" },
                                  on: { click: _vm.showQrCodeScan },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109729")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isEditing && _vm.qrCodeFlag,
                                      expression: "isEditing && qrCodeFlag",
                                    },
                                  ],
                                  attrs: { variant: "primary", block: "" },
                                  on: { click: _vm.hideQrCodeScan },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109732")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.qrCodeFlag
            ? _c("qrcode-stream", {
                on: { decode: _vm.onDecode, init: _vm.onInit },
              })
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "fix-form-row",
                  attrs: {
                    id: "fieldset-4",
                    label: _vm.$t("ml_109713"),
                    "label-cols": "4",
                    "label-align": "right",
                    "label-for": "btn-radios-1",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-form-radio-group", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEditing,
                                    expression: "isEditing",
                                  },
                                ],
                                attrs: {
                                  id: "btn-radios-1",
                                  disabled: _vm.isPreviewing,
                                  options: [
                                    {
                                      text: this.$i18n.t("ml_109710"),
                                      value: "1",
                                    },
                                    {
                                      text: this.$i18n.t("ml_109712"),
                                      value: "2",
                                    },
                                  ],
                                  buttons: "",
                                  "button-variant": "outline-primary",
                                  name: "radio-btn-outline",
                                },
                                on: { input: _vm.showCard },
                                model: {
                                  value: _vm.fuelRecord.FuelType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fuelRecord, "FuelType", $$v)
                                  },
                                  expression: "fuelRecord.FuelType",
                                },
                              }),
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isPreviewing,
                                    expression: "isPreviewing",
                                  },
                                ],
                                attrs: {
                                  id: "input-1",
                                  disabled: _vm.isPreviewing,
                                  trim: "",
                                },
                                model: {
                                  value: _vm.fuelTypeText,
                                  callback: function ($$v) {
                                    _vm.fuelTypeText = $$v
                                  },
                                  expression: "fuelTypeText",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCardFlag,
                      expression: "showCardFlag",
                    },
                  ],
                  staticClass: "fix-form-row",
                  attrs: {
                    id: "fieldset-5",
                    label: _vm.$t("ml_109714"),
                    "label-align": "right",
                    "label-cols": "4",
                    "label-for": "input-5",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-5",
                                  state: _vm.fuelCardNumState,
                                  disabled: _vm.isPreviewing,
                                  required: "",
                                  trim: "",
                                },
                                model: {
                                  value: _vm.fuelRecord.FuelCardNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fuelRecord, "FuelCardNum", $$v)
                                  },
                                  expression: "fuelRecord.FuelCardNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "fix-form-row",
                  attrs: {
                    id: "fieldset-6",
                    label: _vm.$t("ml_109715"),
                    "label-cols": "4",
                    "label-align": "right",
                    "label-for": "input-6",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-6",
                                  state: _vm.fuelVolumeState,
                                  disabled: _vm.isPreviewing,
                                  required: "",
                                  type: "number",
                                  step: "0.01",
                                  min: "0.00",
                                  max: "600.00",
                                  trim: "",
                                },
                                model: {
                                  value: _vm.fuelRecord.FuelVolume,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fuelRecord, "FuelVolume", $$v)
                                  },
                                  expression: "fuelRecord.FuelVolume",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "fix-form-row",
                  attrs: {
                    id: "fieldset-7",
                    label: _vm.$t("ml_109716"),
                    "label-cols": "4",
                    "label-align": "right",
                    "label-for": "input-7",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-7",
                                  state: _vm.odometerState,
                                  disabled: _vm.isPreviewing,
                                  required: "",
                                  type: "number",
                                  trim: "",
                                },
                                model: {
                                  value: _vm.fuelRecord.Odometer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fuelRecord, "Odometer", $$v)
                                  },
                                  expression: "fuelRecord.Odometer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                { staticClass: "fix-form-row" },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "7" } },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.isEditing && !_vm.receiptHasPicture,
                                      expression:
                                        "isEditing && !receiptHasPicture",
                                    },
                                  ],
                                  attrs: { variant: "primary", type: "button" },
                                  on: {
                                    click: _vm.passClickToUploadPictureReceipt,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.receiptUploadText))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.isEditing && _vm.receiptHasPicture,
                                      expression:
                                        "isEditing && receiptHasPicture",
                                    },
                                  ],
                                  staticClass: "margin-top-zero",
                                  attrs: { variant: "danger", type: "button" },
                                  on: { click: _vm.deletePictureReceipt },
                                },
                                [_vm._v(_vm._s(_vm.receiptDeleteText))]
                              ),
                              _c("img", {
                                staticClass: "uploadImage",
                                attrs: { id: "imageUploadReceipt", src: "" },
                                on: { click: _vm.showBigReceipt },
                              }),
                              _c("b-form-file", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                attrs: {
                                  id: "receiptToUpload",
                                  accept: ".jpg",
                                  plain: "",
                                },
                                on: { input: _vm.uploadPctureReceipt },
                                model: {
                                  value: _vm.file,
                                  callback: function ($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.isEditing &&
                                        !_vm.odometerHasPicture,
                                      expression:
                                        "isEditing && !odometerHasPicture",
                                    },
                                  ],
                                  attrs: { variant: "primary", type: "button" },
                                  on: {
                                    click: _vm.passClickToUploadPictureOdometer,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109718")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.isEditing && _vm.odometerHasPicture,
                                      expression:
                                        "isEditing && odometerHasPicture",
                                    },
                                  ],
                                  staticClass: "margin-top-zero",
                                  attrs: { variant: "danger", type: "button" },
                                  on: { click: _vm.deletePictureOdometer },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109727")))]
                              ),
                              _c("img", {
                                staticClass: "uploadImage",
                                attrs: { id: "imageUploadOdometer", src: "" },
                                on: { click: _vm.showBigOdometer },
                              }),
                              _c("b-form-file", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                attrs: {
                                  id: "odometerToUpload",
                                  accept: ".jpg",
                                  plain: "",
                                },
                                on: { input: _vm.uploadPctureOdometer },
                                model: {
                                  value: _vm.file2,
                                  callback: function ($$v) {
                                    _vm.file2 = $$v
                                  },
                                  expression: "file2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "fix-form-row",
                  attrs: {
                    id: "fieldset-8",
                    label: _vm.$t("ml_109719"),
                    "label-cols": "4",
                    "label-align": "right",
                    "label-for": "textarea",
                  },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "textarea",
                                  disabled: _vm.isPreviewing,
                                },
                                model: {
                                  value: _vm.fuelRecord.FuelNotes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fuelRecord, "FuelNotes", $$v)
                                  },
                                  expression: "fuelRecord.FuelNotes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-form-group",
                { staticClass: "fix-form-row" },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isEditing,
                                      expression: "isEditing",
                                    },
                                  ],
                                  attrs: {
                                    type: "button",
                                    block: "",
                                    variant: "danger",
                                  },
                                  on: { click: _vm.clickReset },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109720")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  attrs: {
                                    type: "button",
                                    size: "lg",
                                    block: "",
                                    id: "resetButton",
                                    variant: "danger",
                                  },
                                  on: { click: _vm.onReset },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109720")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isEditing,
                                      expression: "!isEditing",
                                    },
                                  ],
                                  attrs: {
                                    variant: "primary",
                                    block: "",
                                    disabled: _vm.isSubmitting,
                                  },
                                  on: { click: _vm.editClick },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109709")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  attrs: {
                                    size: "lg",
                                    variant: "primary",
                                    block: "",
                                    disabled: _vm.isSubmitting,
                                    id: "editButton",
                                  },
                                  on: { click: _vm.enterEdit },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109709")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isEditing,
                                      expression: "isEditing",
                                    },
                                  ],
                                  attrs: {
                                    type: "button",
                                    variant: "primary",
                                    disabled: _vm.isValid,
                                    block: "",
                                  },
                                  on: { click: _vm.clickConfirm },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109721")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  attrs: {
                                    type: "button",
                                    size: "lg",
                                    variant: "primary",
                                    disabled: _vm.isValid,
                                    block: "",
                                    id: "confirmButton",
                                  },
                                  on: { click: _vm.checkOdometer },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109721")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.isEditing && !_vm.isSubmitting,
                                      expression: "!isEditing && !isSubmitting",
                                    },
                                  ],
                                  attrs: {
                                    type: "button",
                                    block: "",
                                    variant: "primary",
                                  },
                                  on: { click: _vm.submitClick },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109730")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  attrs: {
                                    type: "button",
                                    size: "lg",
                                    block: "",
                                    variant: "primary",
                                    id: "submitButton",
                                  },
                                  on: { click: _vm.onSubmit },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109730")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isEditing && _vm.isSubmitting,
                                      expression: "!isEditing && isSubmitting",
                                    },
                                  ],
                                  attrs: {
                                    variant: "primary",
                                    block: "",
                                    disabled: "",
                                  },
                                },
                                [
                                  _c("b-spinner", { attrs: { small: "" } }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("ml_109731")) + " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-pop-success",
                    "no-close-on-backdrop": "",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                  },
                },
                [
                  _c("b-container", [
                    _c("p", { staticClass: "my-4 big-text" }, [
                      _vm._v(_vm._s(_vm.messageSuccess)),
                    ]),
                  ]),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "primary", block: "" },
                          on: { click: _vm.backToFunctionList },
                        },
                        [_vm._v(_vm._s(_vm.$t("ml_109721")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-pop-image-receipt",
                    "no-close-on-backdrop": "",
                    static: "",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                  },
                },
                [
                  _c("img", {
                    staticClass: "full-screen",
                    attrs: { id: "imageUploadReceiptLarge", src: "" },
                    on: { click: _vm.hideBigReceipt },
                  }),
                ]
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-pop-image-odometer",
                    "no-close-on-backdrop": "",
                    static: "",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                  },
                },
                [
                  _c("img", {
                    staticClass: "full-screen",
                    attrs: { id: "imageUploadOdometerLarge", src: "" },
                    on: { click: _vm.hideBigOdometer },
                  }),
                ]
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-pop-error",
                    "no-close-on-backdrop": "",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                  },
                },
                [
                  _c("b-container", [
                    _c("p", { staticClass: "my-4 big-text" }, [
                      _vm._v(_vm._s(_vm.messageError)),
                    ]),
                  ]),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "primary", block: "" },
                          on: { click: _vm.backToEdit },
                        },
                        [_vm._v(_vm._s(_vm.$t("ml_109733")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-pop-reset",
                    "no-close-on-backdrop": "",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                  },
                },
                [
                  _c("b-container", [
                    _c("p", { staticClass: "my-4 big-text" }, [
                      _vm._v(_vm._s(_vm.messageReset)),
                    ]),
                  ]),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-3",
                                  attrs: { variant: "danger", block: "" },
                                  on: { click: _vm.doReset },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109723")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-3",
                                  attrs: { variant: "primary", block: "" },
                                  on: { click: _vm.doNotReset },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109724")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-pop-warn-odometer",
                    "no-close-on-backdrop": "",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                  },
                },
                [
                  _c("b-container", [
                    _c("p", { staticClass: "my-4 big-text" }, [
                      _vm._v(_vm._s(_vm.messageWarnOdometer)),
                    ]),
                  ]),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-3",
                                  attrs: { variant: "danger", block: "" },
                                  on: { click: _vm.continueConfirm },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109723")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-3",
                                  attrs: { variant: "primary", block: "" },
                                  on: { click: _vm.stopConfirm },
                                },
                                [_vm._v(_vm._s(_vm.$t("ml_109724")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }