<template>
  <div>
    <b-form>
      <!-- <div class="heading">{{ $t("ml_109734") }}</div> -->
      <div>
        <b-form-group
          id="fieldset-1"
          :label="$t(`ml_109706`)"
          label-cols="4"
          label-align="right"
          label-for="input-1"
          class="fix-form-row margin-top"
        >
          <b-container>
            <b-row>
              <b-col>
                <b-form-datepicker
                  id="example-datepicker"
                  right
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  v-model="date"
                  v-if="isEditing"
                  class="mb-2"
                ></b-form-datepicker>
                <b-form-input
                  id="input-1"
                  v-model="date"
                  :disabled="isPreviewing"
                  v-show="isPreviewing"
                  trim
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="fieldset-1"
          :label="$t(`ml_109707`)"
          label-cols="4"
          label-align="right"
          label-for="input-1"
          class="fix-form-row"
        >
          <b-container>
            <b-form-row>
              <b-col>
                <b-form-timepicker
                  v-model="time"
                  locale="de"
                  hide-header
                  no-close-button
                  right
                  v-if="isEditing"
                ></b-form-timepicker>

                <b-form-input
                  id="input-1"
                  v-model="time"
                  :disabled="isPreviewing"
                  v-show="isPreviewing"
                  trim
                ></b-form-input>
              </b-col>
            </b-form-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="fieldset-3"
          :label="$t(`ml_109708`)"
          label-align="right"
          label-cols="4"
          label-for="input-3"
          class="fix-form-row"
        >
          <b-container>
            <b-row>
              <b-col cols="8">
                <b-form-input
                  id="input-3"
                  :disabled="isPreviewing"
                  required
                  v-model="fuelRecord.VehiclePNum"
                  @input="fillCard"
                  :state="vehiclePNumState"
                  trim
                ></b-form-input>
              </b-col>
              <b-col>
                <b-button
                  variant="primary"
                  block
                  v-show="isEditing && !qrCodeFlag"
                  @click="showQrCodeScan"
                  >{{ $t(`ml_109729`) }}</b-button
                >

                <b-button
                  variant="primary"
                  block
                  v-show="isEditing && qrCodeFlag"
                  @click="hideQrCodeScan"
                  >{{ $t(`ml_109732`) }}</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <qrcode-stream
        v-if="qrCodeFlag"
        @decode="onDecode"
        @init="onInit"
      ></qrcode-stream>
      <div>
        <b-form-group
          id="fieldset-4"
          :label="$t(`ml_109713`)"
          label-cols="4"
          label-align="right"
          label-for="btn-radios-1"
          class="fix-form-row"
        >
          <b-container>
            <b-row>
              <b-col>
                <b-form-radio-group
                  id="btn-radios-1"
                  v-show="isEditing"
                  :disabled="isPreviewing"
                  v-model="fuelRecord.FuelType"
                  @input="showCard"
                  :options="[
                    { text: this.$i18n.t(`ml_109710`), value: '1' },
                    { text: this.$i18n.t(`ml_109712`), value: '2' },
                  ]"
                  buttons
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                ></b-form-radio-group>
                <b-form-input
                  id="input-1"
                  v-model="fuelTypeText"
                  :disabled="isPreviewing"
                  v-show="isPreviewing"
                  trim
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="fieldset-5"
          :label="$t(`ml_109714`)"
          v-show="showCardFlag"
          label-align="right"
          label-cols="4"
          label-for="input-5"
          class="fix-form-row"
        >
          <b-container>
            <b-row>
              <b-col>
                <b-form-input
                  id="input-5"
                  v-model="fuelRecord.FuelCardNum"
                  :state="fuelCardNumState"
                  :disabled="isPreviewing"
                  required
                  trim
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="fieldset-6"
          :label="$t(`ml_109715`)"
          label-cols="4"
          label-align="right"
          label-for="input-6"
          class="fix-form-row"
        >
          <b-container>
            <b-row>
              <b-col>
                <b-form-input
                  id="input-6"
                  v-model="fuelRecord.FuelVolume"
                  :state="fuelVolumeState"
                  :disabled="isPreviewing"
                  required
                  type="number"
                  step="0.01"
                  min="0.00"
                  max="600.00"
                  trim
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="fieldset-7"
          :label="$t(`ml_109716`)"
          label-cols="4"
          label-align="right"
          label-for="input-7"
          class="fix-form-row"
        >
          <b-container>
            <b-row>
              <b-col>
                <b-form-input
                  id="input-7"
                  v-model="fuelRecord.Odometer"
                  :state="odometerState"
                  :disabled="isPreviewing"
                  required
                  type="number"
                  trim
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group class="fix-form-row">
          <b-container>
            <b-row>
              <b-col cols="7">
                <b-button
                  variant="primary"
                  type="button"
                  v-show="isEditing && !receiptHasPicture"
                  @click="passClickToUploadPictureReceipt"
                  >{{ receiptUploadText }}</b-button
                >
                <b-button
                  variant="danger"
                  type="button"
                  class="margin-top-zero"
                  v-show="isEditing && receiptHasPicture"
                  @click="deletePictureReceipt"
                  >{{ receiptDeleteText }}</b-button
                >
                <img
                  id="imageUploadReceipt"
                  src
                  class="uploadImage"
                  @click="showBigReceipt"
                />
                <b-form-file
                  v-model="file"
                  @input="uploadPctureReceipt"
                  id="receiptToUpload"
                  v-show="false"
                  accept=".jpg"
                  plain
                ></b-form-file>
              </b-col>
              <b-col>
                <b-button
                  variant="primary"
                  type="button"
                  v-show="isEditing && !odometerHasPicture"
                  @click="passClickToUploadPictureOdometer"
                  >{{ $t(`ml_109718`) }}</b-button
                >
                <b-button
                  variant="danger"
                  type="button"
                  class="margin-top-zero"
                  v-show="isEditing && odometerHasPicture"
                  @click="deletePictureOdometer"
                  >{{ $t(`ml_109727`) }}</b-button
                >
                <img
                  id="imageUploadOdometer"
                  src
                  class="uploadImage"
                  @click="showBigOdometer"
                />
                <b-form-file
                  v-model="file2"
                  @input="uploadPctureOdometer"
                  id="odometerToUpload"
                  v-show="false"
                  accept=".jpg"
                  plain
                ></b-form-file>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group
          id="fieldset-8"
          :label="$t(`ml_109719`)"
          label-cols="4"
          label-align="right"
          label-for="textarea"
          class="fix-form-row"
        >
          <b-container>
            <b-row>
              <b-col>
                <b-form-input
                  id="textarea"
                  v-model="fuelRecord.FuelNotes"
                  :disabled="isPreviewing"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-form-group class="fix-form-row">
          <b-container>
            <b-row>
              <b-col>
                <b-button
                  type="button"
                  @click="clickReset"
                  v-show="isEditing"
                  block
                  variant="danger"
                  >{{ $t(`ml_109720`) }}</b-button
                >
                <b-button
                  type="button"
                  size="lg"
                  @click="onReset"
                  v-show="false"
                  block
                  id="resetButton"
                  variant="danger"
                  >{{ $t(`ml_109720`) }}</b-button
                >
                <b-button
                  variant="primary"
                  block
                  v-show="!isEditing"
                  :disabled="isSubmitting"
                  @click="editClick"
                  >{{ $t(`ml_109709`) }}</b-button
                >
                <b-button
                  size="lg"
                  variant="primary"
                  block
                  v-show="false"
                  :disabled="isSubmitting"
                  id="editButton"
                  @click="enterEdit"
                  >{{ $t(`ml_109709`) }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  type="button"
                  variant="primary"
                  :disabled="isValid"
                  v-show="isEditing"
                  block
                  @click="clickConfirm"
                  >{{ $t(`ml_109721`) }}</b-button
                >
                <b-button
                  type="button"
                  size="lg"
                  variant="primary"
                  :disabled="isValid"
                  v-show="false"
                  block
                  id="confirmButton"
                  @click="checkOdometer"
                  >{{ $t(`ml_109721`) }}</b-button
                >
                <b-button
                  type="button"
                  block
                  variant="primary"
                  @click="submitClick"
                  v-show="!isEditing && !isSubmitting"
                  >{{ $t(`ml_109730`) }}</b-button
                >
                <b-button
                  type="button"
                  size="lg"
                  block
                  variant="primary"
                  @click="onSubmit"
                  id="submitButton"
                  v-show="false"
                  >{{ $t(`ml_109730`) }}</b-button
                >
                <b-button
                  variant="primary"
                  block
                  v-show="!isEditing && isSubmitting"
                  disabled
                >
                  <b-spinner small></b-spinner>
                  {{ $t(`ml_109731`) }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </div>
      <div>
        <b-modal
          id="modal-pop-success"
          no-close-on-backdrop
          centered
          hide-header
          hide-footer
        >
          <b-container>
            <p class="my-4 big-text">{{ messageSuccess }}</p>
          </b-container>
          <b-container>
            <b-button
              class="mt-3"
              variant="primary"
              block
              @click="backToFunctionList"
              >{{ $t(`ml_109721`) }}</b-button
            >
          </b-container>
        </b-modal>
        <b-modal
          id="modal-pop-image-receipt"
          no-close-on-backdrop
          static
          centered
          hide-header
          hide-footer
        >
          <img
            id="imageUploadReceiptLarge"
            src
            class="full-screen"
            @click="hideBigReceipt"
          />
        </b-modal>
        <b-modal
          id="modal-pop-image-odometer"
          no-close-on-backdrop
          static
          centered
          hide-header
          hide-footer
        >
          <img
            id="imageUploadOdometerLarge"
            src
            class="full-screen"
            @click="hideBigOdometer"
          />
        </b-modal>
        <b-modal
          id="modal-pop-error"
          no-close-on-backdrop
          centered
          hide-header
          hide-footer
        >
          <b-container>
            <p class="my-4 big-text">{{ messageError }}</p>
          </b-container>
          <b-container>
            <b-button
              class="mt-3"
              variant="primary"
              block
              @click="backToEdit"
              >{{ $t(`ml_109733`) }}</b-button
            >
          </b-container>
        </b-modal>
        <b-modal
          id="modal-pop-reset"
          no-close-on-backdrop
          centered
          hide-header
          hide-footer
        >
          <b-container>
            <p class="my-4 big-text">{{ messageReset }}</p>
          </b-container>
          <b-container>
            <b-row>
              <b-col>
                <b-button
                  class="mt-3"
                  variant="danger"
                  block
                  @click="doReset"
                  >{{ $t(`ml_109723`) }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  class="mt-3"
                  variant="primary"
                  block
                  @click="doNotReset"
                  >{{ $t(`ml_109724`) }}</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
        <b-modal
          id="modal-pop-warn-odometer"
          no-close-on-backdrop
          centered
          hide-header
          hide-footer
        >
          <b-container>
            <p class="my-4 big-text">{{ messageWarnOdometer }}</p>
          </b-container>
          <b-container>
            <b-row>
              <b-col>
                <b-button
                  class="mt-3"
                  variant="danger"
                  block
                  @click="continueConfirm"
                  >{{ $t(`ml_109723`) }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  class="mt-3"
                  variant="primary"
                  block
                  @click="stopConfirm"
                  >{{ $t(`ml_109724`) }}</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";

import { QrcodeStream } from "vue-qrcode-reader";
// import "vue-qrcode-reader/dist/vue-qrcode-reader.css";

import Vue from "vue";
export default {
  name: "AddFuelRecordFunction",
  components: {
    QrcodeStream,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      file: null,
      receiptHasPicture: false,
      file2: null,
      odometerHasPicture: false,
      date: "",
      time: "",
      h23: "h23",
      instruction: "Enter Fuel Record",
      messageSuccess: "",
      messageError: "",
      messageReset: "",
      messageWarnOdometer: "",
      qrCodeFlag: false,
      isSubmitting: false,
      isEditing: true,

      paymentOptions: [
        { text: this.$i18n.t(`ml_109710`), value: "1" },
        { text: this.$i18n.t(`ml_109712`), value: "2" },
      ],
      errors: [],
      accessToken: "",
      mapCarAndCard: null,
      showCardFlag: true,

      apiUri: process.env.VUE_APP_API_URI,

      fuelRecord: {
        EquipmentID: "300014241",
        FuelUnitPrz: "0.00",
        TimeZone: "",
        Language: "",
        MeasPoint: "303921",
        Mdocm: "5625613",
        FuelType: "1",
        VehiclePNum: "", //"辽ACN084",
        Date: "04/30/2020",
        MeasPos: "FLMG_EST_PRICE",
        MeasUnit: "L",
        Readr: "ZHANGPY",
        Totac: "1250,56",
        DriverApexId: "DENGXY",
        FuelCardNum: "",
        FuelDate: "20200508",
        FuelNotes: "", //"DriverNotes",
        FuelStaAdd: "",
        FuelTime: "104616",
        FuelVolume: "", //"123.45",
        receiptPicture: "",
        odometerPicture: "",
        Odometer: "",
      },
    };
  },
  created() {},
  mounted() {
    Vue.$authService.acquireTokenSilent().then(
      (token) => {
        console.log(token.accessToken);
      },
      (error) => {
        console.error(error);
        this.$router.push({ path: "/" });
      }
    );

    // console.log("mounted");
    // this.$i18n.locale = 'en';
    // this.$i18n.locale = "zh";
    this.$i18n.locale = window.navigator.language.slice(0, 2);
    this.fuelRecord.DriverApexId = Vue.$authService
      .getUser()
      .userName.split("@")[0];

    // const successText = this.$i18n.t(`ml_109725`);
    // this.messageSuccess = successText;
    // this.$bvModal.show("modal-pop-success");

    Vue.$authService.getAccessToken().then(
      (token) => {
        console.log(token.accessToken);
        this.accessToken = token.accessToken;
        // call to reduce time
        const getOptions = {
          method: "get",
          headers: { Authorization: `Bearer ` + this.accessToken },
          url:
            this.apiUri + "card?vehicle=" + process.env.VUE_APP_VEHICLE_NUMBER,
        };
        axios(getOptions).then(
          (response) => {
            console.log("first call succeeded");
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );

    const d = new Date();

    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    let hour = "" + d.getHours();
    let minute = "" + d.getMinutes();
    let second = "" + d.getSeconds();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    this.date = [year, month, day].join("-");

    if (hour.length < 2) {
      hour = "0" + hour;
    }
    if (minute.length < 2) {
      minute = "0" + minute;
    }
    if (second.length < 2) {
      second = "0" + second;
    }

    this.time = [hour, minute, second].join(":");

    this.fuelRecord.Language = window.navigator.language.slice(0, 2);
    this.fuelRecord.TimeZone = new Date().toString().substring(25, 31);
  },
  computed: {
    // a computed getter

    vehiclePNumState() {
      return this.fuelRecord.VehiclePNum === "" ? false : true;
    },
    fuelVolumeState() {
      return this.fuelRecord.FuelVolume === "" ||
        this.fuelRecord.FuelVolume > 600 ||
        this.fuelRecord.FuelVolume === "0"
        ? false
        : true;
    },
    odometerState() {
      return this.fuelRecord.Odometer === "" || this.fuelRecord.Odometer === "0"
        ? false
        : true;
    },
    fuelCardNumState() {
      return this.fuelRecord.FuelType === "1" &&
        this.fuelRecord.FuelCardNum === ""
        ? false
        : true;
    },
    isValid: function () {
      if (
        this.fuelRecord.VehiclePNum === "" ||
        !this.odometerHasPicture ||
        !this.receiptHasPicture ||
        this.fuelRecord.FuelVolume === "" ||
        this.fuelRecord.FuelVolume > 600 ||
        this.fuelRecord.FuelVolume === "0" ||
        this.fuelRecord.Odometer === "0" ||
        this.fuelRecord.Odometer === "" ||
        (this.fuelRecord.FuelType === "1" && this.fuelRecord.FuelCardNum === "")
      ) {
        return true;
      } else {
        return false;
      }
    },
    fuelTypeText: function () {
      let text = "";
      if (this.fuelRecord.FuelType == "1") {
        // text = "Fuel Card";
        text = this.$i18n.t(`ml_109710`);
        // text = this.paymentOptions.text[0];
      } else {
        // text = "Cash";
        text = this.$i18n.t(`ml_109712`);
      }
      return text;
    },
    receiptUploadText: function () {
      let text = "";
      if (this.fuelRecord.FuelType == "1") {
        // text = "Fuel Card";
        text = this.$i18n.t(`ml_109717`);
        // text = this.paymentOptions.text[0];
      } else {
        // text = "Cash";
        text = this.$i18n.t(`ml_109735`);
      }
      return text;
    },
    receiptDeleteText: function () {
      let text = "";
      if (this.fuelRecord.FuelType == "1") {
        // text = "Fuel Card";
        text = this.$i18n.t(`ml_109728`);
        // text = this.paymentOptions.text[0];
      } else {
        // text = "Cash";
        text = this.$i18n.t(`ml_109736`);
      }
      return text;
    },
    isPreviewing: function () {
      // `this` points to the vm instance
      return !this.isEditing;
    },
  },
  methods: {
    onContext(ctx) {
      this.context = ctx;
    },
    deletePictureReceipt: function (event) {
      document.getElementById("imageUploadReceipt").src = "";
      this.file = null;
      this.receiptHasPicture = false;
    },
    deletePictureOdometer: function (event) {
      document.getElementById("imageUploadOdometer").src = "";
      this.file2 = null;
      this.odometerHasPicture = false;
    },
    passClickToUploadPictureReceipt: function (event) {
      // alert(123);
      let uploadFileTagReceipt = document.getElementById("receiptToUpload");
      uploadFileTagReceipt.click();
      // alert(this.file.name);
      return false;
    },
    passClickToUploadPictureOdometer: function (event) {
      // alert(123);
      let uploadFileTagOdometer = document.getElementById("odometerToUpload");
      uploadFileTagOdometer.click();
      // alert(this.file.name);
      return false;
    },
    showBigReceipt: function () {
      this.$bvModal.show("modal-pop-image-receipt");
      this.showBigReceiptPicture();
    },
    showBigOdometer: function () {
      this.$bvModal.show("modal-pop-image-odometer");
      this.showBigOdometerPicture();
    },
    hideBigReceipt: function () {
      this.$bvModal.hide("modal-pop-image-receipt");
    },
    hideBigOdometer: function () {
      this.$bvModal.hide("modal-pop-image-odometer");
    },
    showBigReceiptPicture: function () {
      document.getElementById("imageUploadReceiptLarge").src =
        document.getElementById("imageUploadReceipt").src;
    },
    showBigOdometerPicture: function () {
      document.getElementById("imageUploadOdometerLarge").src =
        document.getElementById("imageUploadOdometer").src;
    },
    uploadPctureReceipt: function (event) {
      if (this.file == null) {
      } else {
        let previewReceipt = document.getElementById("imageUploadReceipt");
        // Ensure it's an image
        if (this.file.type.match(/image.*/)) {
          // console.log("An image has been loaded");
          // Load the image
          let reader = new FileReader();
          reader.onload = function (readerEvent) {
            let image = new Image();
            image.onload = function (imageEvent) {
              // Resize the image
              let canvas = document.createElement("canvas"),
                max_size = 1000,
                width = image.width,
                height = image.height;
              if (width > height) {
                if (width > max_size) {
                  height *= max_size / width;
                  width = max_size;
                }
              } else {
                if (height > max_size) {
                  width *= max_size / height;
                  height = max_size;
                }
              }
              canvas.width = width;
              canvas.height = height;
              var ctx = canvas.getContext("2d");
              ctx.drawImage(image, 0, 0, width, height);
              let dataUrl = canvas.toDataURL("image/jpeg");
              // this.fuelRecord.receiptPicture = dataUrl;
              // previewReceipt.src = dataUrl;
              document.getElementById("imageUploadReceipt").src = dataUrl;
              // console.log(dataUrl);
            };
            image.src = readerEvent.target.result;
            // previewReceipt.src = readerEvent.target.result;

            // console.log(readerEvent.target.result);
          };
          reader.readAsDataURL(this.file);
          // console.log("reader completed");
        }
        // previewReceipt.src = this.fuelRecord.receiptPicture;
        this.receiptHasPicture = true;
      }
    },
    uploadPctureOdometer: function (event) {
      if (this.file2 == null) {
      } else {
        let preview = document.getElementById("imageUploadOdometer");
        // Ensure it's an image
        if (this.file2.type.match(/image.*/)) {
          // console.log("An image has been loaded");
          // Load the image
          let reader2 = new FileReader();
          reader2.onload = function (readerEvent) {
            let image2 = new Image();
            image2.onload = function (imageEvent) {
              // Resize the image
              let canvas2 = document.createElement("canvas"),
                max_size = 1000,
                width = image2.width,
                height = image2.height;
              if (width > height) {
                if (width > max_size) {
                  height *= max_size / width;
                  width = max_size;
                }
              } else {
                if (height > max_size) {
                  width *= max_size / height;
                  height = max_size;
                }
              }
              canvas2.width = width;
              canvas2.height = height;
              var ctx2 = canvas2.getContext("2d");
              ctx2.drawImage(image2, 0, 0, width, height);
              let dataUrl = canvas2.toDataURL("image/jpeg");
              // this.fuelRecord.odometerPicture = dataUrl;
              document.getElementById("imageUploadOdometer").src = dataUrl;
              // console.log(dataUrl);
            };
            image2.src = readerEvent.target.result;
            // preview.src = readerEvent.target.result;
            // console.log(readerEvent.target.result);
          };
          reader2.readAsDataURL(this.file2);
          // console.log("reader completed");
        }
        this.odometerHasPicture = true;
      }
    },
    removeBase64Head: function (base64Data) {
      if (0 == base64Data.indexOf("data:image/")) {
        let c = base64Data.indexOf("base64,") + 7;
        base64Data = base64Data.substring(c);
        return base64Data;
      } else {
        return base64Data;
      }
    },
    getPicture: function () {
      if (
        document
          .getElementById("imageUploadReceipt")
          .src.indexOf("data:image/") == 0
      ) {
        this.fuelRecord.receiptPicture = this.removeBase64Head(
          document.getElementById("imageUploadReceipt").src
        );
      }
      if (
        document
          .getElementById("imageUploadOdometer")
          .src.indexOf("data:image/") == 0
      ) {
        this.fuelRecord.odometerPicture = this.removeBase64Head(
          document.getElementById("imageUploadOdometer").src
        );
      }
    },
    checkPicture: function () {
      if (this.fuelRecord.receiptPicture != "") {
      }
      if (this.fuelRecord.odometerPicture != "") {
      }
    },
    showCard: function (event) {
      // console.log("radio change");
      if (this.fuelRecord.FuelType == "1") {
        this.showCardFlag = true;
      } else {
        this.showCardFlag = false;
      }
    },
    fillCard: function (event) {
      if(this.fuelRecord.VehiclePNum.length >5){
      Vue.$authService.getAccessToken().then(
        (token) => {
          console.log(token.accessToken);
          this.accessToken = token.accessToken;
          console.log("get access token before get fuel card");
          // this.fuelRecord.VehiclePNum = this.fuelRecord.VehiclePNum.toUpperCase();
          const getOptions = {
            method: "get",
            headers: { Authorization: `Bearer ` + this.accessToken },

            url: this.apiUri + "card?vehicle=" + this.fuelRecord.VehiclePNum,
          };
          axios(getOptions).then(
            (response) => {
              this.fuelRecord.FuelCardNum = response.data.d.results[0].cardNum;
            },
            (error) => {
              console.error(error);
            }
          );
        },
        (error) => {
          console.error(error);
        }
      );        
      }

    },
    clickConfirm: function () {
      let confirmButton = document.getElementById("confirmButton");
      confirmButton.click();
    },
    checkOdometer: function (event) {
      if (
        this.fuelRecord.Odometer < 1000 ||
        this.fuelRecord.Odometer > 2000000
      ) {
        const warnOdometerText = this.$i18n.t(`ml_109737`);
        this.messageWarnOdometer = warnOdometerText;
        this.$bvModal.show("modal-pop-warn-odometer");
      } else {
        this.quitEdit();
      }
    },
    continueConfirm: function () {
      this.$bvModal.hide("modal-pop-warn-odometer");
      this.isEditing = false;
    },
    stopConfirm: function () {
      this.$bvModal.hide("modal-pop-warn-odometer");
    },
    quitEdit: function (event) {
      this.isEditing = false;
    },
    editClick: function () {
      let editButton = document.getElementById("editButton");
      editButton.click();
    },
    enterEdit: function (event) {
      this.isEditing = true;
    },
    onDecode: function (decodedString) {
      this.fuelRecord.VehiclePNum = decodedString;
      this.fillCard();
      this.qrCodeFlag = false;
    },
    showQrCodeScan: function (event) {
      this.qrCodeFlag = true;
    },
    hideQrCodeScan: function (event) {
      this.qrCodeFlag = false;
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    checkForm: function (e) {
      if (this.fuelRecord.VehiclePNum == "") {
        return true;
      }

      e.preventDefault();
    },
    setCardEmptyIfCashIsSelected: function (e) {
      if (this.fuelRecord.FuelType == "1") {
      } else {
        this.fuelRecord.FuelCardNum = "";
      }
    },
    submitClick: function () {
      let submitButton = document.getElementById("submitButton");
      submitButton.click();
    },
    onSubmit(evt) {
      // evt.preventDefault();
      // alert("submit");
      this.setCardEmptyIfCashIsSelected();
      this.getPicture();
      this.checkPicture();
      this.transformDate();
      this.transformTime();

      // alert(JSON.stringify(this.fuelRecord));

      this.postFuelRecord();
    },
    clickReset: function () {
      let resetButton = document.getElementById("resetButton");
      resetButton.click();
    },
    onReset(evt) {
      // alert("reset");
      // evt.preventDefault();
      // alert("reset");
      // Reset our form values
      // const resetText = "Confirm to reset";
      const resetText = this.$i18n.t(`ml_109722`);
      this.messageReset = resetText;
      this.$bvModal.show("modal-pop-reset");

      // Trick to reset/clear native browser form validation state
    },

    doReset: function () {
      this.$bvModal.hide("modal-pop-reset");
      // this.isEditing = true;
      this.fuelRecord.VehiclePNum = "";
      this.fuelRecord.FuelVolume = "";
      this.fuelRecord.Odometer = "";
      this.fuelRecord.FuelType = "1";
      this.fuelRecord.FuelCardNum = "";
      this.fuelRecord.FuelNotes = "";

      const d = new Date();
      // d.setDate(d.getDate());

      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();
      let hour = "" + d.getHours();
      let minute = "" + d.getMinutes();
      let second = "" + d.getSeconds();

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      this.date = [year, month, day].join("-");
      // console.log(this.date);
      if (hour.length < 2) {
        hour = "0" + hour;
      }
      if (minute.length < 2) {
        minute = "0" + minute;
      }
      if (second.length < 2) {
        second = "0" + second;
      }
      // this.fuelRecord.FuelTime=time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();
      this.time = [hour, minute, second].join(":");
      // console.log(this.time);
    },
    doNotReset: function () {
      this.$bvModal.hide("modal-pop-reset");
    },

    transformTime: function () {
      // alert(this.time);
      this.fuelRecord.FuelTime = this.time.split(":").join("");
      // alert(this.fuelRecord.FuelTime);
    },
    transformDate: function () {
      // alert(this.date);

      this.fuelRecord.FuelDate = this.date.split("-").join("");
      // alert(this.fuelRecord.FuelDate);
    },
    backToFunctionList: function () {
      this.$bvModal.hide("modal-pop-success");
      this.$router.push({ path: "/" });
    },
    backToEdit: function () {
      this.$bvModal.hide("modal-pop-error");
      this.isEditing = true;
    },

    postFuelRecord: async function () {
      try {
        const postUri = this.apiUri + "equipmentmeasuredocs";
        this.isSubmitting = true;
        // const response = await axios.post(postUri, { d: this.fuelRecord });

        const postOptions = {
          method: "post",
          headers: { Authorization: `Bearer ` + this.accessToken },
          data: { d: this.fuelRecord },
          url: postUri,
        };
        const response = await axios(postOptions);
        // const response = await actualPost();
        // console.log(response);
        // alert(response.status);
        this.isSubmitting = false;
        // const successText = "Fuel record submitted successfully.";
        const successText = this.$i18n.t(`ml_109725`);
        // this.$bvModal.msgBoxOk(successText)
        this.messageSuccess = successText;
        this.$bvModal.show("modal-pop-success");
        // setTimeout(this.backToFunctionList, 3000);
      } catch (error) {
        console.log(`client error occur`);
        console.log(JSON.stringify(error));

        // error.response is true , error.response != undefined
        // console.error(error);
        // alert(error.response.status);
        // this.errors.push(error);
        this.isSubmitting = false;

        if (error.response) {
          console.log(`error.response has true value`);
          if (error.response.status == 401) {
            console.log(error.response.status);
            
            this.messageError = this.$i18n.t(`ml_109738`);
          } else if (
            error.response.status == 500 &&
            error.response.data.startsWith(
              ` internal PostEquipmentMeasureDocs api call http status is not 200 `
            )
          ) {
            console.log(error.response.status);
            
            this.messageError = this.$i18n.t(`ml_109740`);
          } else if (error.response.status == 400 && error.response.data) {
            console.log(error.response.status);
            this.messageError = error.response.data.error.message.value;
          } else {
            console.log(
              `error.response has true value but status has false value`
            );
            
            this.messageError = this.$i18n.t(`ml_109739`);
          }
        } else {
          console.log(`error.response has false value`);
          
          this.messageError = this.$i18n.t(`ml_109741`);
        }
        // this.messageError = error.response.data.error.message.value;

        // this.$bvModal.msgBoxOk(error.response.data.error.message.value);

        this.$bvModal.show("modal-pop-error");

        // send log to lambda
        // console.log(JSON.stringify(error));
        try {
          var errorConfigDataExcludePhoto = JSON.parse(error.config.data);
          errorConfigDataExcludePhoto.d.odometerPicture = ``;
          errorConfigDataExcludePhoto.d.receiptPicture = ``;

          var errorExcludePhoto = error;
          errorExcludePhoto.config.data = JSON.stringify(
            errorConfigDataExcludePhoto
          );

          console.log(errorExcludePhoto);
          console.log(JSON.stringify(errorExcludePhoto));

          const postErrorUri = this.apiUri + "clienterror";

          const errorObjectToLog = {};
          if (error.response) {
            errorObjectToLog.responseStatus =
              `true value` + error.response.status;
            // errorObjectToLog.responseData = JSON.stringify(error.response.data);
          } else {
            errorObjectToLog.responseStatus = `false value`;
          }
          errorObjectToLog.errorMessage = error.message;
          errorObjectToLog.requestVehicle = this.fuelRecord.VehiclePNum;
          errorObjectToLog.requestCard = this.fuelRecord.FuelCardNum;

          const postErrorOptions = {
            method: "post",

            // data: { d: JSON.stringify(errorExcludePhoto) },
            data: { d: errorObjectToLog },
            url: postErrorUri,
          };
          const response = await axios(postErrorOptions);
          console.log(`send client error log to lambda, success`);
        } catch {
          console.log(
            `client error occur when send client error log to lambda`
          );
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.uploadImage {
  width: 100px;
  height: 100px;
  overflow: hidden;
  /* float: left; */
}
img[src=""] {
  display: none;
}

.heading {
  font-size: 26px;
  /* margin-bottom: 24px; */
}
/* .feedback{
  text-align: left;
} */
.big-text {
  font-size: 24px;
}
.full-screen {
  max-width: 100%;
  /* margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
  max-height: 100%;
  /* display: flex;
    position: fixed;
    z-index: 100000; */
}
.fix-form-row {
  margin-left: 0px;
  margin-right: 0px;
}
.margin-top {
  margin-top: 1rem;
}
.margin-top-zero {
  margin-top: 0px;
}
</style>
