// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/home.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\nh3[data-v-ee60ba60] {\r\n  margin: 40px 0 0;\n}\nul[data-v-ee60ba60] {\r\n  list-style-type: none;\r\n  padding: 0;\n}\nli[data-v-ee60ba60] {\r\n  display: inline-block;\r\n  margin: 0 10px;\n}\na[data-v-ee60ba60] {\r\n  color: #42b983;\n}\n.hide[data-v-ee60ba60] {\r\n  visibility: hidden;\n}\n.margin-top[data-v-ee60ba60]{\r\n  margin-top:100px;\n}\n.color-white[data-v-ee60ba60]{\r\n  color:white;\n}\n.font-size[data-v-ee60ba60]{\r\n  font-size: 2rem;\n}\r\n/*\r\n.material-symbols-outlined {\r\n  font-variation-settings:\r\n  'FILL' 0,\r\n  'wght' 400,\r\n  'GRAD' 0,\r\n  'opsz' 48\r\n}\r\n*/\n.icon-position[data-v-ee60ba60]{\r\n  font-size: 40px;\r\n  vertical-align: middle;\r\n  margin-right: 12px;\r\n  float:left;\n}\n.text-position[data-v-ee60ba60]{\r\n  vertical-align: middle;\r\n  float:left;\r\n  padding-top: 5px;\n}\n.font-size-30px[data-v-ee60ba60]{\r\n  font-size: 26px;\n}\n.custom-height[data-v-ee60ba60]{\r\n  height:66px;\n}\r\n/* fallback */\n@font-face {\r\n  font-family: 'Material Symbols Outlined';\r\n  font-style: normal;\r\n  font-weight: 100 700;\r\n  font-display: block;\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n.material-symbols-outlined[data-v-ee60ba60] {\r\n  font-family: 'Material Symbols Outlined';\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-size: 48px;\r\n  line-height: 1;\r\n  letter-spacing: normal;\r\n  text-transform: none;\r\n  display: inline-block;\r\n  white-space: nowrap;\r\n  word-wrap: normal;\r\n  direction: ltr;\r\n  text-rendering: optimizeLegibility;\r\n  -webkit-font-smoothing: antialiased;\n}\n.button-position[data-v-ee60ba60]{\r\n  width: 340px;\r\n  margin-left: 20px;\n}\r\n\r\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
