var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              true
                ? _c(
                    "b-button",
                    {
                      staticClass: "color-white font-size",
                      attrs: { variant: "primary", size: "lg", block: "" },
                    },
                    [_vm._v("Qlik Sense iframe Test")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-row", [
        _c("iframe", {
          attrs: {
            src: "https://dev-qliksense.america.apci.com/single/?appid=bb4fa316-c80a-4932-95fe-6d2a31bc20dc&sheet=BEUjVL&theme=horizon&opt=ctxmenu,currsel",
            title: "qlik-sense",
            height: "1000px",
            width: "1500px",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }