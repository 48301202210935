var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { staticClass: "margin-top" },
        [
          _c(
            "b-col",
            { staticClass: "pb-5", attrs: { lg: "4" } },
            [
              !_vm.isLoggedIn
                ? _c(
                    "b-button",
                    {
                      staticClass: "color-white font-size button-position",
                      attrs: {
                        variant: "primary",
                        block: "",
                        size: "lg",
                        disabled: "",
                      },
                    },
                    [
                      _c("b-spinner", {
                        staticStyle: { width: "30px", height: "30px" },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "font-size-30px",
                          staticStyle: { "margin-left": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.$t("ml_109726")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "pb-5", attrs: { lg: "4" } },
            [
              _vm.isLoggedIn
                ? _c(
                    "b-button",
                    {
                      staticClass:
                        "color-white font-size custom-height button-position",
                      attrs: {
                        variant: "primary",
                        size: "lg",
                        block: "",
                        to: "/addFuelRecordFunction",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          float: "left",
                          "object-position": "0px -5px",
                        },
                        attrs: { src: require("@/assets/gas.png") },
                      }),
                      _c(
                        "span",
                        { staticClass: "text-position font-size-30px" },
                        [_vm._v(_vm._s(_vm.$t("ml_109734")))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "pb-5", attrs: { lg: "4" } },
            [
              _vm.isLoggedIn
                ? _c(
                    "b-button",
                    {
                      staticClass:
                        "color-white font-size custom-height button-position",
                      attrs: { variant: "primary", size: "lg", block: "" },
                      on: { click: _vm.goToEvcr },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          float: "left",
                          "object-position": "0px 0px",
                        },
                        attrs: { src: require("@/assets/evcr.png") },
                      }),
                      _c(
                        "span",
                        { staticClass: "text-position font-size-30px" },
                        [_vm._v(_vm._s(_vm.$t("ml_109742")))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "pb-5", attrs: { lg: "4" } },
            [
              _vm.isLoggedIn && _vm.whetherEmployeeCanAccess
                ? _c(
                    "b-button",
                    {
                      staticClass:
                        "color-white font-size custom-height button-position",
                      attrs: { variant: "primary", size: "lg", block: "" },
                      on: { click: _vm.goToDmp },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          float: "left",
                          "object-position": "0px -5px",
                        },
                        attrs: { src: require("@/assets/dmp.png") },
                      }),
                      _c(
                        "span",
                        { staticClass: "text-position font-size-30px" },
                        [_vm._v(_vm._s(_vm.$t("ml_109743")))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "pb-5", attrs: { lg: "4" } },
            [
              _c(
                "b-button",
                {
                  ref: "login",
                  staticClass: "hide",
                  attrs: { size: "lg" },
                  on: { click: _vm.login },
                },
                [_vm._v("Login")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }