import axios, { AxiosStatic } from 'axios';
import AuthService from '@/services/AuthService';
import { Component, Vue } from 'vue-property-decorator';

export default class APIService {
    private _authService: AuthService = Vue.$authService;

    constructor() {
        
    }

    public async callGetAPI() {
        const token = await this._authService.getToken();
        //set the access_token for calling the api
        let headers = { headers: { 'Content-Type': 'application/json;charset=utf-8', 'Authorization': 'Bearer ' + token.accessToken } };
        //const response = await axios.get('https://m4phn0i3d3.execute-api.us-east-1.amazonaws.com/Prod/api/values', headers);
        const response = await axios.get('https://5z8dzvsy3l.execute-api.ap-southeast-1.amazonaws.com/Prod/api/values', headers);
        return response.data;
    }

    public async callPostAPI(postData: string) {
        try {
            const token = await this._authService.getToken();
            if (token == null) return '认证失败，系统自动重新登录';
            //set the access_token for calling the api
            let headers = { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + token.accessToken } };
            //const response = await axios.post('https://m4phn0i3d3.execute-api.us-east-1.amazonaws.com/Prod/api/values', '=' + postData, headers);
            const response = await axios.post('https://5z8dzvsy3l.execute-api.ap-southeast-1.amazonaws.com/Prod/api/values', '=' + postData, headers);        
            return response.data;
        }
        catch(err) {
            return ('callPostAPI' + err);
        }
    }

}