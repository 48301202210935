export default {
    employeeDataCollection: [
        {
            mail: 'ZHANGPY@airproducts.com'
        },        
        {
        mail: 'HUANGH27@airproducts.com'
        },
        {
        mail: 'WUSQ@airproducts.com'
        },
        {
        mail: 'HUANGSX@airproducts.com'
        },
        {
        mail: 'QINZH@airproducts.com'
        },
        {
        mail: 'ZHONGHZ@airproducts.com'
        },
        {
        mail: 'LIUWH@airproducts.com'
        },
        {
        mail: 'ZHANGZR@airproducts.com'
        },
        {
        mail: 'HANZ@airproducts.com'
        },
        {
        mail: 'DENGH5@airproducts.com'
        },
        {
        mail: 'LIJ83@airproducts.com'
        },
        {
        mail: 'LINZ10@airproducts.com'
        },
        {
        mail: 'ZHANGF18@airproducts.com'
        },
        {
        mail: 'GAOP3@airproducts.com'
        },
        {
        mail: 'XIAOX1@airproducts.com'
        },
        {
        mail: 'ZENGH2@airproducts.com'
        },
        {
        mail: 'WUD14@airproducts.com'
        },
        {
        mail: 'YANGJ36@airproducts.com'
        },
        {
        mail: 'CHEM@airproducts.com'
        },
        {
        mail: 'CHENS44@airproducts.com'
        },
        {
        mail: 'LIANGH4@airproducts.com'
        },
        {
        mail: 'LIANGD2@airproducts.com'
        },
        {
        mail: 'ZHOUX11@airproducts.com'
        },
        {
        mail: 'PANP1@airproducts.com'
        },
        {
        mail: 'WANGG3@airproducts.com'
        },
        {
        mail: 'CHENH32@airproducts.com'
        },
        {
        mail: 'QINZ1@airproducts.com'
        },
        {
        mail: 'ZHONGY6@airproducts.com'
        },
        {
        mail: 'WUS13@airproducts.com'
        },
        {
        mail: 'YANGC33@airproducts.com'
        },
        {
        mail: 'ZHUJ28@airproducts.com'
        },
        {
        mail: 'WUZ11@airproducts.com'
        },
        {
        mail: 'HUANGH24@airproducts.com'
        },
        {
        mail: 'WANGH43@airproducts.com'
        },
        {
        mail: '45422@airproducts.com'
        },
        {
        mail: 'HUANGJ32@airproducts.com'
        },
        {
        mail: 'XIAOZ3@airproducts.com'
        },
        {
        mail: 'CHENS42@airproducts.com'
        },
        {
        mail: 'TAOB@airproducts.com'
        },
        {
        mail: 'ZHANGY79@airproducts.com'
        },
        {
        mail: 'LONGL1@airproducts.com'
        },
        {
        mail: 'ZHUC10@airproducts.com'
        },
        {
        mail: 'HUX11@airproducts.com'
        },
        {
        mail: 'ZHOUQ8@airproducts.com'
        },
        {
        mail: 'CHENJ53@airproducts.com'
        },
        {
        mail: 'HUY13@airproducts.com'
        },
        {
        mail: 'WUJ46@airproducts.com'
        },
        {
        mail: 'XUQ11@airproducts.com'
        },
        {
        mail: 'LAIJ2@airproducts.com'
        },
        {
        mail: 'XIAOH6@airproducts.com'
        },
        {
        mail: 'ZHOUD9@airproducts.com'
        },
        {
        mail: 'LIUH30@airproducts.com'
        },
        {
        mail: 'WANGJ86@airproducts.com'
        },
        {
        mail: 'MAJ12@airproducts.com'
        },
        {
        mail: 'ZHANGZ31@airproducts.com'
        },
        {
        mail: 'OUH2@airproducts.com'
        },
        {
        mail: 'ZHOUB3@airproducts.com'
        },
        {
        mail: 'PENGW3@airproducts.com'
        },
        {
        mail: 'XIAOQ@airproducts.com'
        },
        {
        mail: 'YEY4@airproducts.com'
        },
        {
        mail: 'SONGQ2@airproducts.com'
        },
        {
        mail: 'ZHANGX48@airproducts.com'
        },
        {
        mail: 'YANGZ4@airproducts.com'
        },
        {
        mail: 'ZHIJ@airproducts.com'
        },
        {
        mail: 'ZENGL@airproducts.com'
        },
        {
        mail: 'PANY3@airproducts.com'
        },
        {
        mail: 'LONGT2@airproducts.com'
        },
        {
        mail: 'SONGZ3@airproducts.com'
        },
        {
        mail: 'LIH28@airproducts.com'
        },
        {
        mail: 'ZHUOG@airproducts.com'
        },
        {
        mail: 'ZHANGF10@airproducts.com'
        },
        {
        mail: 'FUG@airproducts.com'
        },
        {
        mail: 'LIAOJ3@airproducts.com'
        },
        {
        mail: 'LIS36@airproducts.com'
        },
        {
        mail: 'HUANGJ24@airproducts.com'
        },
        {
        mail: 'LIJ53@airproducts.com'
        },
        {
        mail: 'PANY2@airproducts.com'
        },
        {
        mail: 'WUJ37@airproducts.com'
        },
        {
        mail: 'CHENJ37@airproducts.com'
        },
        {
        mail: 'LONGW@airproducts.com'
        },
        {
        mail: 'ZHENGS9@airproducts.com'
        },
        {
        mail: 'ZHANGL24@airproducts.com'
        },
        {
        mail: 'LIUB14@airproducts.com'
        },
        {
        mail: 'SHENY5@airproducts.com'
        },
        {
        mail: 'HEC@airproducts.com'
        },
        {
        mail: 'ZHUH10@airproducts.com'
        },
        {
        mail: 'ZHOUG3@airproducts.com'
        },
        {
        mail: 'LING@airproducts.com'
        },
        {
        mail: 'WUJ35@airproducts.com'
        },
        {
        mail: 'HUY7@airproducts.com'
        },
        {
        mail: 'ZHUS6@airproducts.com'
        },
        {
        mail: 'XIEP@airproducts.com'
        },
        {
        mail: 'LIUX11@airproducts.com'
        },
        {
        mail: 'ZHONGY2@airproducts.com'
        },
        {
        mail: 'YEG1@airproducts.com'
        },
        {
        mail: 'JIANGY5@airproducts.com'
        },
        {
        mail: 'WANGH25@airproducts.com'
        },
        {
        mail: 'WANGY43@airproducts.com'
        },
        {
        mail: 'FENGJ4@airproducts.com'
        },
        {
        mail: 'LIUH21@airproducts.com'
        },
        {
        mail: 'LUOC2@airproducts.com'
        },
        {
        mail: 'WANGX31@airproducts.com'
        },
        {
        mail: 'LIUY25@airproducts.com'
        },
        {
        mail: 'LIG17@airproducts.com'
        },
        {
        mail: 'WEIS2@airproducts.com'
        },
        {
        mail: 'ZHENGY7@airproducts.com'
        },
        {
        mail: 'LIUG8@airproducts.com'
        },
        {
        mail: 'TANGL5@airproducts.com'
        },
        {
        mail: 'HANS6@airproducts.com'
        },
        {
        mail: 'CHENH15@airproducts.com'
        },
        {
        mail: 'HANS5@airproducts.com'
        },
        {
        mail: 'ZHANGJ60@airproducts.com'
        },
        {
        mail: 'LIANGX2@airproducts.com'
        },
        {
        mail: 'WANGL26@airproducts.com'
        },
        {
        mail: 'ZHONGZ2@airproducts.com'
        },
        {
        mail: 'LIUT14@airproducts.com'
        },
        {
        mail: 'DONGK@airproducts.com'
        },
        {
        mail: 'HED1@airproducts.com'
        },
        {
        mail: 'YED5@airproducts.com'
        },
        {
        mail: 'WANGF11@airproducts.com'
        },
        {
        mail: 'LID15@airproducts.com'
        },
        {
        mail: 'WANGZ18@airproducts.com'
        },
        {
        mail: 'XIANP1@airproducts.com'
        },
        {
        mail: 'YANGF2@airproducts.com'
        },
        {
        mail: 'LUOJ5@airproducts.com'
        },
        {
        mail: 'TANGJQ1@airproducts.com'
        },
        {
        mail: 'WENGJ@airproducts.com'
        },
        {
        mail: 'ZHANGZ13@airproducts.com'
        },
        {
        mail: 'PANZ@airproducts.com'
        },
        {
        mail: 'WANGJ43@airproducts.com'
        },
        {
        mail: 'ZHONGR@airproducts.com'
        },
        {
        mail: 'AOZ@airproducts.com'
        },
        {
        mail: 'WUW5@airproducts.com'
        },
        {
        mail: 'ZHENGW2@airproducts.com'
        },
        {
        mail: 'LIUFUY@airproducts.com'
        },
        {
        mail: 'ZHUS4@airproducts.com'
        },
        {
        mail: 'CHENB7@airproducts.com'
        },
        {
        mail: 'LINY6@airproducts.com'
        },
        {
        mail: 'XUZ1@airproducts.com'
        },
        {
        mail: 'FUC1@airproducts.com'
        },
        {
        mail: 'ZHANGC17@airproducts.com'
        },
        {
        mail: 'HUANGJ14@airproducts.com'
        },
        {
        mail: 'DONGJ7@airproducts.com'
        },
        {
        mail: 'ZHONGG1@airproducts.com'
        },
        {
        mail: 'JIANGW@airproducts.com'
        },
        {
        mail: 'ZHANGH20@airproducts.com'
        },
        {
        mail: 'WANGP7@airproducts.com'
        },
        {
        mail: 'YIH1@airproducts.com'
        },
        {
        mail: 'LINZ2@airproducts.com'
        },
        {
        mail: 'FANY3@airproducts.com'
        },
        {
        mail: 'QIUD@airproducts.com'
        },
        {
        mail: 'FENGJ1@airproducts.com'
        },
        {
        mail: 'CHENJ11@airproducts.com'
        },
        {
        mail: 'LUJ1@airproducts.com'
        },
        {
        mail: 'YANGX4@airproducts.com'
        },
        {
        mail: 'LID3@airproducts.com'
        },
        {
        mail: 'CHENQ2@airproducts.com'
        },
        {
        mail: 'ZHAOM@airproducts.com'
        },
        {
        mail: 'LIUW4@airproducts.com'
        },
        {
        mail: 'ZHOUQF@airproducts.com'
        },
        {
        mail: 'TIANB1@airproducts.com'
        },
        {
        mail: 'LONGD4@airproducts.com'
        },
        {
        mail: 'HUANGY13@airproducts.com'
        },
        {
        mail: 'CHENX24@airproducts.com'
        },
        {
        mail: 'QIUG@airproducts.com'
        },
        {
        mail: 'LIF20@airproducts.com'
        },
        {
        mail: 'DONGX1@airproducts.com'
        },
        {
        mail: 'OUJ2@airproducts.com'
        },
        {
        mail: 'LITJ@airproducts.com'
        },
        {
        mail: 'LIH13@airproducts.com'
        },
        {
        mail: 'YANGX26@airproducts.com'
        },
        {
        mail: 'SUD2@airproducts.com'
        },
        {
        mail: 'ZHANGL61@airproducts.com'
        },
        {
        mail: 'LIANGJ4@airproducts.com'
        },
        {
        mail: 'CHENJ26@airproducts.com'
        },
        {
        mail: 'LINDQ1@airproducts.com'
        },
        {
        mail: 'CHENZ24@airproducts.com'
        },
        {
        mail: 'YANGG12@airproducts.com'
        },
        {
        mail: 'WANGH21@airproducts.com'
        },
        {
        mail: 'SUNJ27@airproducts.com'
        },
        {
        mail: 'OUZ@airproducts.com'
        },
        {
        mail: 'XUH10@airproducts.com'
        },
        {
        mail: 'LIG19@airproducts.com'
        },
        {
        mail: 'DAIZ1@airproducts.com'
        },
        {
        mail: 'XIEZ@airproducts.com'
        },
        {
        mail: 'FENGK2@airproducts.com'
        },
        {
        mail: 'CHENH8@airproducts.com'
        }
          
    ]
}